import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pill", "product"];

  initialize() {
    this.processFilter();
  }

  chooseAllManufacturer(_event) {
    this.currentManufacturerId = "";
  }

  chooseAllTechnology(_event) {
    this.currentTechnologyId = "";
  }

  chooseManufacturer(event) {
    this.currentManufacturerId =
      event.currentTarget.dataset.filterManufacturerId;
  }

  chooseTechnology(event) {
    this.currentTechnologyId = event.currentTarget.dataset.filterTechnologyId;
  }

  get currentManufacturerId() {
    return this.data.get("currentManufacturerId");
  }

  set currentManufacturerId(id) {
    this.data.set("currentManufacturerId", id);
    this.processFilter();
  }

  get currentTechnologyId() {
    return this.data.get("currentTechnologyId");
  }

  set currentTechnologyId(id) {
    this.data.set("currentTechnologyId", id);
    this.processFilter();
  }

  processFilter() {
    this.filterProducts();
    this.highlightPills();
  }

  filterProducts() {
    this.productTargets.forEach(e => {
      if (
        (this.currentManufacturerId == "" ||
          e.dataset.manufacturerId == this.currentManufacturerId) &&
        (this.currentTechnologyId == "" ||
          e.dataset.technologyId == this.currentTechnologyId)
      ) {
        e.style.display = "flex";
      } else {
        e.style.display = "none";
      }
    });
  }

  highlightPills() {
    this.pillTargets.forEach(e => {
      if (
        this.currentManufacturerId == e.dataset.filterManufacturerId ||
        this.currentTechnologyId == e.dataset.filterTechnologyId
      ) {
        e.className = "pill__highlighted";
      } else {
        e.className = "pill";
      }
    });
  }
}
