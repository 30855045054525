import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.linkPath = this.element.dataset.linkPath;
  }

  link() {
    window.location.assign(this.linkPath);
  }
}
